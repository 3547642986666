// This function takes any webflow form and sends the data to strapi.
// using the EZ Form plugin, strapi saves this data and emails it out
// to notification recipients using the sendgrid API.
// It also displays our success or error banner components

import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useSubmitForm = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    let form = {};
    for (let i = 0; i < e.target.length; i++) {
      if (e.target[i].type !== "submit") {
        form[e.target[i].name] = e.target[i].value;
      }
    }

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/ezforms/submit`, {
        formData: form,
      })
      .then(() => {
        navigate(window.location.pathname + "?status=success");
      })
      .catch((error) => {
        navigate(window.location.pathname + "?status=error");
      });
  };

  // return those functions
  return {
    handleSubmit,
  };
};
