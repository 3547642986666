// Weblfow Accordions tend not to work well once exported
// This allows us to have a Strapi CMS linked Accordion
// May need some modificatons to match your webflow code structure

import React, { useState } from "react";
import RichText from "./RichText";
import { v4 as uuid } from "uuid";
import "../css/Accordion.css";

const FAQs = ({ data }) => {
  const [lastClicked, setLastClicked] = useState(null);

  if (!data) return null;
  return (
    <div className="container faq w-container">
      {data.map((el) => {
        return <AccordionItem key={uuid()} header={el.attributes.question} body={el.attributes.answer} id={el.id} lastClicked={lastClicked} setLastClicked={setLastClicked} />;
      })}
    </div>
  );
};

const AccordionItem = ({ header, body, id, lastClicked, setLastClicked }) => {
  const handleClick = () => {
    if (lastClicked === id) {
      setLastClicked(null);
    } else {
      setLastClicked(id);
    }
  };

  return (
    <div data-hover="false" data-delay={0} data-w-id="410c6ef2-99a5-84e3-21b3-68cad6921e8f" className="faqdropdown w-dropdown" style={lastClicked === id ? { zIndex: 901 } : {}}>
      <div className={`faqdropdowntoggle w-dropdown-toggle ${lastClicked === id ? "w--open" : null}`} onClick={handleClick}>
        <div className="arrow w-icon-dropdown-toggle" />
        <h2 className="heading-21">{header}</h2>
      </div>
      <nav className="faqanswer w-dropdown-list" style={lastClicked === id ? { display: "block", opacity: 1 } : { display: "block", opacity: 0, height: 0 }}>
        <p className="faqanswertext">
          {" "}
          <RichText text={body}></RichText>
        </p>
      </nav>
    </div>
  );
};

export default FAQs;
// w-dropdown-list

// closed
//

// open
// display: block; opacity: 1; width: 1536px;

// w-dropdown
// open
