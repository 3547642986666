import React from "react";
import Page from "../components/Page";
import { useAuthFunctions } from "../hooks/useAuthFunctions";

const initialValues = {
  email: "test@gmail.com",
  password: "12345678",
  firstName: "John",
};

const AuthPage = () => {
  return (
    <Page>
      <SignupForm />
      <LoginForm />
      <LogoutSection />
    </Page>
  );
};

export default AuthPage;

const SignupForm = () => {
  const { handleSignup } = useAuthFunctions();

  return (
    // forms must have onSubmit, inputs must have name fields
    <form onSubmit={handleSignup}>
      <h1>Signup</h1>
      {/* email and password are mandatory user fields */}
      <label>Email</label>
      <br />
      <input type="email" name="email" defaultValue={initialValues.email} />
      <br />
      <label>Password</label>
      <br />
      <input type="password" name="password" defaultValue={initialValues.password} />
      <br />
      {/* An example of optional user fields */}
      <label>First Name</label>
      <br />
      <input type="text" name="firstName" defaultValue={initialValues.firstName} />
      <br />

      <input type="submit" />
    </form>
  );
};

export const LoginForm = () => {
  const { handleLogin } = useAuthFunctions();
  return (
    // forms must have onSubmit, inputs must have name fields
    <form onSubmit={handleLogin}>
      <h1>Login</h1>
      <label>Email</label>
      <br />
      <input type="email" name="email" defaultValue={initialValues.email} />
      <br />
      <label>Password</label>
      <br />
      <input type="password" name="password" defaultValue={initialValues.password} />
      <br />
      <input type="submit" />
    </form>
  );
};

export const LogoutSection = () => {
  const { handleLogout } = useAuthFunctions();
  return (
    <div>
      <h1>Logout</h1>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};
