import { useDispatch } from "react-redux";
import * as AuthActions from "../store/actions/auth";
import { pullFormData } from "../util/pullFormData";
import { useNavigate } from "react-router-dom";

// handles Strapi auth flow: login
export const useAuthFunctions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // create a function to be called by our form
  const handleSignup = (e) => {
    e.preventDefault();
    let data = pullFormData(e);

    dispatch(AuthActions.register(data));
    // navigate("/LogIn");
  };

  // create a function to be called by our form
  const handleLogin = (e) => {
    if (e) {
      e.preventDefault();
      let data = pullFormData(e);
      data.identifier = data.email;
      dispatch(AuthActions.login(data));
      // navigate("/Updates");
    }
  };

  // // create a function to be called by our form
  const handleLogout = () => {
    dispatch(AuthActions.logout());
    navigate("/");
  };

  // return those functions
  return {
    handleLogin,
    handleSignup,
    handleLogout,
  };
};
