// This file defines the strapi endpoints you want to pull dtata from (collections & single types)
// This data will be fetched every time the page is loaded or refreshed

// slug = database table name, also known as API ID (Plural)
// fields = what columns from the table do you want to use?
// populate = how to show nested components & relations (doc: https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields)
// There's two formats for doing this. Populate can be a string or qs.stringify()

import qs from "qs";

export const strapiPublicEndpoints = [
  // Single Types
  {
    slug: "navbar",
    fields: ["logo", "link"],
    populate: qs.stringify({
      populate: {
        logo: {
          populate: "*",
        },
        link: {
          populate: {
            item: {
              populate: "*",
            },
          },
        },
      },
    }),
  },
  {
    slug: "footer",
    fields: ["logo", "newsletterHeading", "newsletterLink", "instagramLink", "facebookLink"],
    // populate: "populate=*",
    populate: qs.stringify({
      populate: {
        logo: {
          populate: "populate=*",
        },
      },
    }),
  },
  {
    slug: "landing-page",
    fields: [
      "mainHeading",
      "mainImage",
      "calendarUrl",
      "lessonsImage",
      "mainDesc",
      "lessonsText",
      "lessonsHeading",
      "newsletterHeading",
      "newsletterImage",
      "newsletterText",
      "testimonialImages",
      "instagramLink",
      "facebookLink",
      "locationText",
      "goalsText",
      "servicesText",
      "registrationButton",
      "missionDesc",
      "missionHeading",
      "missionVideoURL",
      "OneOnOneLink",
    ],
    populate: qs.stringify({
      populate: {
        registrationButton: {
          populate: {
            item: {
              populate: "*",
            },
          },
        },
        mainImage: {
          populate: "populate=*",
        },
        lessonsImage: {
          populate: "populate=*",
        },
        newsletterImage: {
          populate: "populate=*",
        },
        testimonialImages: {
          populate: "*",
        },
      },
    }),
  },
  {
    slug: "classes-page",
    fields: ["mainHeading", "mainDesc", "mainImage"],
    populate: qs.stringify({
      populate: {
        mainImage: {
          populate: "*",
        },
      },
    }),
  },
  {
    slug: "private-lessons-page",
    fields: ["mainHeading", "bodyText", "mainImage"],
    populate: qs.stringify({
      populate: {
        mainImage: {
          populate: "populate=*",
        },
      },
    }),
  },
  {
    slug: "about-page",
    fields: ["mainHeading", "mainDesc", "mainImage", "missionHeading", "missionDesc", "missionVideoURL", "Staff"],
    populate: qs.stringify({
      populate: {
        Staff: {
          populate: {
            item: {
              populate: "*",
            },
            Photo: {
              populate: "populate=*",
            },
          },
        },
        mainImage: {
          populate: "populate=*",
        },
      },
    }),
  },

  {
    slug: "login-page",
    fields: ["loginHeading", "loginText", "loginImage"],
    populate: "populate=*",
  },

  {
    slug: "signup-page",
    fields: ["signupHeading", "signupText", "signupImage"],
    populate: "populate=*",
  },

  {
    slug: "faq-page",
    fields: ["smallHeading", "mainHeading", "mainDesc"],
  },
  {
    slug: "contact-page",
    fields: ["address", "phoneNumber", "email"],
    populate: "populate=*",
  },

  // Collections
  {
    slug: "classes",
    fields: ["category", "title", "description", "activitiesDescription", "registrationLink"],
    populate: "populate=*",
  },
  {
    slug: "faqs",
    fields: ["question", "answer"],
    // populate: "populate=*",
    populate: qs.stringify({
      populate: {
        question: {
          populate: "*",
        },
        answer: {
          populate: "*",
        },
      },
    }),
  },
];

export const strapiAuthenticatedEndpoints = [
  {
    slug: "news-page",
    fields: ["calendarUrl", "News"],
    populate: qs.stringify({
      populate: {
        News: {
          populate: {
            item: {
              populate: "*",
            },
          },
        },
      },
    }),
  },
];

// An example for getting complicated with the populate feild:
//   populate: qs.stringify({
//     populate: {
//       exampleSlider: {
//         populate: {
//           items: {
//             populate: "background",
//           },
//         },
//       },
//       exampleAccordion: {
//         populate: ["items"],
//       },
//     },
//   }),
