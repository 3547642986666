import React, { useEffect } from "react";
import WebFont from "webfontloader";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

function Head() {
  const { id } = useParams();

  useEffect(() => {
    // If your head code includes any google fonts, place them here
    WebFont.load({
      google: {
        families: [
          "Roboto:regular,500,700,900",
          "Inter:regular,500,600,700,800,900",
        ],
      },
    });
  }, []);

  //   Depending on the page we're on, we'll want different head tags. make a 'case' for each page slug,
  //   Convert your webflow <head></head> code to JSX and paste it between these <Helmet></Helmet> tags
  switch (window.location.pathname) {
    case "/":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>JaneBook'sDogSchool</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/janebooksdogschool.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.ico"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );
    case "/About":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us</title>
          <meta content="About Us" property="og:title" />
          <meta content="About Us" property="twitter:title" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/janebooksdogschool.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.ico"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );
    case "/Classes":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Classes</title>
          <meta content="Classes" property="og:title" />
          <meta content="Classes" property="twitter:title" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/janebooksdogschool.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.ico"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );
    case "/FAQ":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>FAQ</title>
          <meta content="FAQ" property="og:title" />
          <meta content="FAQ" property="twitter:title" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/janebooksdogschool.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.ico"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );
    case "/Contact":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Us</title>
          <meta content="Contact Us" property="og:title" />
          <meta content="Contact Us" property="twitter:title" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/janebooksdogschool.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.ico"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );
    case "/1on1":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>1-1 Training</title>
          <meta content="1-1 Training" property="og:title" />
          <meta content="1-1 Training" property="twitter:title" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/janebooksdogschool.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.ico"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );
    case "/LogIn":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login</title>
          <meta content="Login" property="og:title" />
          <meta content="Login" property="twitter:title" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/janebooksdogschool.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.ico"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );
    case "/SignUp":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sign Up</title>
          <meta content="Sign Up" property="og:title" />
          <meta content="Sign Up" property="twitter:title" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/janebooksdogschool.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.ico"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );
    case "/Updates":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>News &amp; Calendar Updates</title>
          <meta content="News & Calendar Updates" property="og:title" />
          <meta content="News & Calendar Updates" property="twitter:title" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/janebooksdogschool.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.ico"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    default:
      return (
        <Helmet>
          <meta charset="utf-8" />
          <title>Example Site | Error Page</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
        </Helmet>
      );
  }
}

export default Head;
