import React from "react";
import Page from "../components/Page";
import RichText from "../components/RichText";

// Import all the functions we need
import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";
import { Link } from "react-router-dom";


function OneOnOnePage() {
  // access our data so that we can display it
  const singleTypeSlug = "private-lessons-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;
  return (
    <Page
      data-wf-page="6356dade120e8a301e004142"
      data-wf-site="6352a347a0546222959cdf3a"
    >
      <div className="_1on1section wf-section">
        <div className="_1on1container w-container">
          <div className="_1on1textdiv">
            <h1 className="groomingheading">
              <strong
                data-w-id="1d8453ed-1945-7753-954d-49d3ee8873b6"
                style={{ opacity: 0 }}
                className="bold-text"
              >
                {cmsData.mainHeading}
              </strong>
            </h1>
            <p
              data-w-id="1d8453ed-1945-7753-954d-49d3ee8873b8"
              style={{ opacity: 0 }}
              className="paragraph-18"
            >
              <RichText text = {cmsData.bodyText}></RichText>
            </p>
            <div className="_1on1buttons">
              <Link to = {"/Contact"} className="button w-button">
                Contact Us
              </Link>
              <a href={cmsData.registrationLink} className="button w-button">
                Register Now
              </a>
            </div>
          </div>
          <div className="div-block-3">
            <img
              // src="images/PrivateLessonsImage.png"
              src = {cmsData.mainImage.data && cmsData.mainImage.data.attributes.url}
              loading="lazy"
              style={{ opacity: 0 }}
              data-w-id="6b807f9d-9703-cf40-2254-be119307d964"
              sizes="100vw"
              srcSet="images/PrivateLessonsImage-p-500.png 500w, images/PrivateLessonsImage-p-800.png 800w, images/PrivateLessonsImage-p-1080.png 1080w, images/PrivateLessonsImage-p-1600.png 1600w, images/PrivateLessonsImage-p-2000.png 2000w, images/PrivateLessonsImage.png 2184w"
              alt=""
              className="image-3"
            />
            <div
              data-w-id="6b807f9d-9703-cf40-2254-be119307d965"
              style={{ opacity: 0 }}
              className="div-block-2"
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default OneOnOnePage;
