import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";

export const PURCHASE = "PURCHASE";

export const purchase = (data, quantity) => {
  console.log("Purchasing Items");
  return async (dispatch) => {
    try {
      // setup stripe
      var stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

      // make request
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/shop-items/createCheckoutSession`,
        data: {
          ...data,
          quantity: quantity,
        },
      };
      const response = await axios(config);

      stripe.redirectToCheckout({ sessionId: response.data.sessionId });
      // dispatch({
      //   type: PURCHASE,
      //   data: response.data,
      // });
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  };
};
