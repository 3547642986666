import React from "react";
import Page from "../components/Page";
import RichText from "../components/RichText";

// Import all the functions we need
import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";
import { Link } from "react-router-dom";

function IndexPage() {
  // access our data so that we can display it
  const singleTypeSlug = "landing-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;
  return (
    <Page data-wf-page="6352a347a054625cfd9cdf3b" data-wf-site="6352a347a0546222959cdf3a">
      <div className="herosection wf-section">
        <div className="container w-container">
          <div className="text">
            <h1 data-w-id="75513d12-7741-616a-ada6-95a9b22552d9" style={{ opacity: 0 }} className="heading">
              {cmsData.mainHeading}
            </h1>
            <p data-w-id="c222c977-926d-43d4-ea3d-f6f8f9994a09" style={{ opacity: 0 }} className="paragraph">
              {cmsData.mainDesc}
            </p>
            <div className="div-block-14">
              <Link to={"/Classes"} className="button w-button">
                Register Now
              </Link>
            </div>
          </div>
          <div className="image">
            <h1
              data-w-id="492ac1b9-c06d-e23b-66ef-a482320f2d24"
              style={{
                opacity: 0,
                backgroundImage: `url(${cmsData.mainImage.data && cmsData.mainImage.data.attributes.url})`,
              }}
              className="heroimagebackground"
            >
              0
            </h1>
            <h1 data-w-id="814e8d87-5938-b662-61a6-e7fd25f0bedb" style={{ opacity: 0 }} className="heroimagebackground _2 hero">
              0
            </h1>
          </div>
          <div className="social-media">
            <a href={cmsData.facebookLink} className="link-block w-inline-block">
              <img src="images/FacebookIcon.svg" loading="lazy" alt="" className="image-8" />
            </a>
            <a href={cmsData.instagramLink} className="w-inline-block">
              <img src="images/InstagramIcon.svg" loading="lazy" alt="" className="image-9" />
            </a>
          </div>
        </div>
      </div>
      <div className="chooseussection wf-section">
        <div className="container choose w-container">
          <h1 data-w-id="6aebbcb6-edc5-c365-41d9-c045c0e00c1b" style={{ opacity: 0 }} className="heading-2">
            Why Choose Us?
          </h1>
          <div className="cardcontainer">
            <div data-w-id="c8ee5997-067c-f946-eab6-92c1e0927c39" style={{ opacity: 0 }} className="card">
              <img src="images/LocationCard_1.svg" loading="lazy" alt="" />
              <h4 className="heading-3">Location</h4>
              <p className="paragraph-2">{cmsData.locationText}</p>
            </div>
            <div data-w-id="45e4611b-38f5-d536-1c03-371b659f831c" style={{ opacity: 0 }} className="card">
              <img src="images/DogCard.svg" loading="lazy" alt="" />
              <h4 className="heading-3">Our Goals</h4>
              <p className="paragraph-2">{cmsData.goalsText}</p>
            </div>
            <div data-w-id="8c77556d-86c9-9541-cb15-e6c2ef6496c3" style={{ opacity: 0 }} className="card">
              <img src="images/ServiceCard.svg" loading="lazy" alt="" />
              <h4 className="heading-3">Wide Range of Services</h4>
              <p className="paragraph-2">{cmsData.servicesText}</p>
            </div>
          </div>
          <div className="choosebuttons">
            <Link to={"/Classes"} className="button w-button">
              Register Now
            </Link>
          </div>
          <img src="images/Paw.svg" loading="lazy" alt="" className="image-2" />
        </div>
      </div>
      <div className="privatelessonssection wf-section">
        <div className="container _1-1 w-container">
          <div className="div-block-4">
            <h1 data-w-id="8f6616d1-df05-12f7-2663-f6ba4f936c77" style={{ opacity: 0 }} className="heading-4">
              {cmsData.lessonsHeading}
            </h1>
            <p data-w-id="b4fa9d97-42d4-317e-8a20-31cbcd756dd9" style={{ opacity: 0 }} className="paragraph-3">
              <RichText text={cmsData.lessonsText}></RichText>
            </p>
            <div className="div-block-8">
              <Link to={"/1on1"} className="button w-button">
                Learn More
              </Link>
              <a href={cmsData.OneOnOneLink} className="button w-button">
                Register Now
              </a>
            </div>
          </div>
          <div className="div-block-3">
            <img
              src={cmsData.lessonsImage.data && cmsData.lessonsImage.data.attributes.url}
              loading="lazy"
              style={{ opacity: 0 }}
              data-w-id="c4b34e2d-c4c9-df5a-c4e7-9692bc25b4b7"
              sizes="(max-width: 479px) 0px, 100vw"
              alt=""
              className="image-3"
            />
            <div data-w-id="6f1ab51f-4325-52f8-10fa-7486f8eda60a" style={{ opacity: 0 }} className="div-block-2" />
          </div>
        </div>
      </div>
      <div className="classschedulesection wf-section">
        <div className="container class w-container">
          <h1 data-w-id="a09ac0dc-52da-9834-5f0b-27687b0511d2" style={{ opacity: 0 }} className="heading-5">
            Class Schedules
          </h1>
          <p data-w-id="a11f7dd1-2c7f-d09e-36dd-f188aa5a0cd5" style={{ opacity: 0 }} className="paragraph-4">
            Check out our upcoming classes and register now!
          </p>
          <div className="calendar w-embed w-iframe">
            <iframe src={cmsData.calendarUrl} style={{ border: "solid 1px #777" }} width={1030} height={600} frameBorder={0} scrolling="no" />
          </div>
          <div className="calendar phone w-embed w-iframe">
            <iframe src={cmsData.calendarUrl} style={{ border: "solid 1px #777" }} width={300} height={300} frameBorder={0} scrolling="no" />
          </div>
          <div className="calendar tablet w-embed w-iframe">
            <iframe src={cmsData.calendarUrl} style={{ border: "solid 1px #777" }} width={500} height={300} frameBorder={0} scrolling="no" />
          </div>
          <Link to={"/Classes"} className="button w-button">
            Register Now
          </Link>
        </div>
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class _2 _3" />
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class _2" />
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class" />
      </div>
      <div className="newslettersection wf-section">
        <div className="container-2 w-container">
          <div className="div-block-5">
            <div>
              <h1 data-w-id="d9065bb8-e39c-2d5f-7727-d406e514ff7d" style={{ opacity: 0 }} className="heading-7">
                {cmsData.newsletterHeading}
              </h1>
              <h2 data-w-id="c0d9a1f8-993c-f0e9-34aa-2981e2af6ec1" style={{ opacity: 0 }} className="heading-6">
                Subscribe to our newsletter!
              </h2>
              <p data-w-id="5b5c9b7a-f7f8-1066-221b-0b0f56d61795" style={{ opacity: 0 }} className="paragraph-5">
                {cmsData.newsletterText}
              </p>
            </div>
            <div className="form-block w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                action="https://jbdogschool.us2.list-manage.com/subscribe/post?u=9e6971fa7add79747b015b61d&amp;id=47656db476&amp;f_id=00b949e0f0"
                method="post"
                className="form-2"
              >
                <div>
                  <label htmlFor="FNAME" className="fieldlabel">
                    NAME
                  </label>
                  <input type="text" className="text-field w-input" maxLength={256} name="FNAME" data-name="FNAME" placeholder="Enter your name" id="FNAME" />
                </div>
                <div>
                  <label htmlFor="EMAIL-3" className="fieldlabel">
                    EMAIL ADDRESS
                  </label>
                  <input type="email" className="text-field w-input" maxLength={256} name="EMAIL" data-name="EMAIL" placeholder="Enter your email address" id="EMAIL-3" required="" />
                </div>
                <input type="submit" data-wait="Please wait..." defaultValue="Subscribe Now" className="button w-button" />
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
          <img
            src={cmsData.newsletterImage.data && cmsData.newsletterImage.data.attributes.url}
            loading="lazy"
            style={{ opacity: 0 }}
            data-w-id="a220dbeb-fb04-ab0c-2a3e-859919980f31"
            sizes="(max-width: 479px) 100vw, (max-width: 991px) 47vw, (max-width: 1919px) 45vw, 524.3125px"
            alt=""
            className="image-5"
          />
        </div>
      </div>
      <div className="section wf-section">
        <div className="container choose w-container">
          <h1 data-w-id="356253d6-f3a5-3482-a48d-5c7e9b67c3b8" style={{ opacity: 0 }} className="heading-16">
            {cmsData.missionHeading}
          </h1>
          <p data-w-id="356253d6-f3a5-3482-a48d-5c7e9b67c3ba" style={{ opacity: 0 }} className="paragraph-6">
            {cmsData.missionDesc}
          </p>
          <div data-w-id="356253d6-f3a5-3482-a48d-5c7e9b67c3bc" style={{ opacity: 0, paddingTop: "56.17021276595745%" }} className="w-video w-embed">
            <iframe
              src={cmsData.missionVideoURL}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="gallery wf-section">
        <h1 className="heading-9">Our Customers</h1>
        <div className="container w-container">
          <div className="w-layout-grid grid-6">
            {cmsData.testimonialImages.data &&
              cmsData.testimonialImages.data.map((el) => {
                return <img src={el.attributes.url} loading="lazy" sizes="(max-width: 479px) 0px, 100vw" alt="" className="galleryimage" />;
              })}
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }} className="preloader">
        <div
          className="lottie-animation"
          data-w-id="37ed0229-cc97-07e8-b087-d8e81682af4e"
          data-animation-type="lottie"
          data-src="documents/65014-dog-walking.json"
          data-loop={0}
          data-direction={1}
          data-autoplay={1}
          data-is-ix2-target={0}
          data-renderer="svg"
          data-default-duration="0.8333333333333334"
          data-duration={0}
        />
      </div>
    </Page>
  );
}

export default IndexPage;
