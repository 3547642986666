// DOCUMENTATION:
// https://reactrouter.com/docs/en/v6

// This file sets up the page navigation or the sitemap of our website.
// This is not our Navbar component

import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

// Import the necessary page files from ../pages/Something.js
import IndexPage from "../pages/IndexPage";
import ClassesPage from "../pages/ClassesPage";
import AboutPage from "../pages/AboutPage";
import OneOnOnePage from "../pages/OneOnOnePage";
import FAQPage from "../pages/FAQPage";
import UpdatesPage from "../pages/UpdatesPage";
import ErrorPage from "../pages/ErrorPage";
import ContactPage from "../pages/ContactPage";
import AuthPage from "../pages/AuthPage";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";

function Navigation(props) {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* We need to add a route for each of our pages. 
          Path is the page slug www.something.com/slug. 
          Element is the React component to display when we get to that slug. */}
          <Route path="/" element={<IndexPage />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/Contact" element={<ContactPage />} />
          <Route path="/Classes" element={<ClassesPage />} />
          <Route path="/About" element={<AboutPage />} />
          <Route path="/1on1" element={<OneOnOnePage />} />
          <Route path="/FAQ" element={<FAQPage />} />
          <Route path="/Updates" element={<UpdatesPage />} />
          <Route path="/LogIn" element={<LoginPage />} />
          <Route path="/SignUp" element={<SignupPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Navigation;
