import React from "react";
import Page from "../components/Page";
import { useAuthFunctions } from "../hooks/useAuthFunctions";
import { Link } from "react-router-dom";

// Import all the functions we need
import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";

function LoginPage() {
  // access our data so that we can display it
  const singleTypeSlug = "login-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  const { handleLogin } = useAuthFunctions();
  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;
  return (
    <Page
      data-wf-page="6356da5e87513a0a4922a4b8"
      data-wf-site="6352a347a0546222959cdf3a"
    >
      <div className="section-2 wf-section">
        <div className="container w-container">
          <div className="text">
            <h1 className="heading-20"> {cmsData.loginHeading} </h1>
            <p className="paragraph-17">{cmsData.loginText} </p>
            <div className="form-block-3 w-form">
              <form onSubmit={handleLogin}>
                <label htmlFor="email" className="fieldlabel">
                  Email Address
                </label>
                <input
                  type="email"
                  className="text-field w-input"
                  maxLength={256}
                  name="email"
                  placeholder="Enter your email"
                  id="email"
                />
                <label htmlFor="password" className="fieldlabel">
                  Password
                </label>
                <input
                  type="password"
                  className="text-field w-input"
                  maxLength={256}
                  name="password"
                  placeholder="Enter your password"
                  id="password"
                  required=""
                />
                <div className="div-block-20">
                  <input
                    type="submit"
                    defaultValue="Submit"
                    className="button w-button"
                  />
                  <Link to="/SignUp" className="button stroke w-button">
                    Need an account?
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <div className="div-block-19">
            <img
              src="images/loginpage.png"
              loading="lazy"
              srcSet="images/loginpage-p-500.png 500w, images/loginpage.png 756w"
              sizes="(max-width: 479px) 100vw, 45vw"
              alt=""
              className="image-13"
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }} className="preloader">
        <div
          className="lottie-animation"
          data-w-id="a3977c95-c3d5-7dfe-8b6b-ce450d160c18"
          data-animation-type="lottie"
          data-src="documents/65014-dog-walking.json"
          data-loop={0}
          data-direction={1}
          data-autoplay={1}
          data-is-ix2-target={0}
          data-renderer="svg"
          data-default-duration="0.8333333333333334"
          data-duration={0}
        />
      </div>
    </Page>
  );
}

export default LoginPage;
