import React from "react";
import Page from "../components/Page";
import RichText from "../components/RichText";


// Import all the functions we need
import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";

function AboutPage() {
  // access our data so that we can display it
  const singleTypeSlug = "about-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;
  return (
    <Page
      data-wf-page="6356da45a7a147d068897575"
      data-wf-site="6352a347a0546222959cdf3a"
    >
      <div className="herosection classes wf-section">
        <div className="container w-container">
          <div className="text">
            <h1
              data-w-id="a76324eb-6bd5-18db-c76d-1f6e42fa3ae8"
              style={{ opacity: 0 }}
              className="heading"
            >
              {cmsData.mainHeading}
            </h1>
            <p
              data-w-id="a76324eb-6bd5-18db-c76d-1f6e42fa3aea"
              style={{ opacity: 0 }}
              className="paragraph"
            >
              {cmsData.mainDesc}
            </p>
          </div>
          <div className="image">
            <h1
              data-w-id="a76324eb-6bd5-18db-c76d-1f6e42fa3aef"
              style={{
                opacity: 0,
                backgroundImage: `url(${
                  cmsData.mainImage.data &&
                  cmsData.mainImage.data.attributes.url
                })`,
              }}
              className="heroimagebackground aboutus"
            >
              0
            </h1>
            <h1
              data-w-id="a76324eb-6bd5-18db-c76d-1f6e42fa3af1"
              style={{ opacity: 0 }}
              className="heroimagebackground _2 hero"
            >
              0
            </h1>
          </div>
        </div>
      </div>
      <div className="aboutherosection wf-section">
        {cmsData.Staff &&
          cmsData.Staff.map((item) => {
            return (
              <div
                className="container w-container"
                style={{ margin: "30px auto" }}
              >
                <div className="image">
                  <h1
                    data-w-id="8e01efc5-8802-3bee-4920-bd676786eb70"
                    style={{
                      opacity: 0,
                      backgroundImage: `url(${
                        item.Photo.data && item.Photo.data.attributes.url
                      })`,
                    }}
                    className="heroimagebackground _3 _4"
                  >
                    1
                  </h1>
                  <h1
                    data-w-id="ad40bbc0-34a6-0810-4f68-a1151f343ff5"
                    style={{ opacity: 0 }}
                    className="heroimagebackground _2 _4"
                  >
                    1
                  </h1>
                </div>
                <div className="text">
                  <h1
                    data-w-id="ddfa0222-c931-e2d8-56a5-eca81c9405bc"
                    style={{ opacity: 0 }}
                    className="heading-15"
                  >
		    {item.Tag}
                  </h1>
                  <h2
                    data-w-id="e6b45940-8ed9-4bdf-67b6-5ead46765ccc"
                    style={{ opacity: 0 }}
                    className="heading-14"
                  >
                    {item.StaffName}
                  </h2>
                  <p
                    data-w-id="2cb5bc42-e02e-11b9-702f-756e0bfa2613"
                    style={{ opacity: 0 }}
                    className="paragraph-8"
                  >
                    <RichText text={item.bio}></RichText>
                  </p>
                </div>
              </div>
            );
          })}
      </div>
      <div style={{ display: "flex" }} className="preloader">
        <div
          className="lottie-animation"
          data-w-id="84ad1d74-f4aa-341f-977f-2db9296e265b"
          data-animation-type="lottie"
          data-src="documents/65014-dog-walking.json"
          data-loop={0}
          data-direction={1}
          data-autoplay={1}
          data-is-ix2-target={0}
          data-renderer="svg"
          data-default-duration="0.8333333333333334"
          data-duration={0}
        />
      </div>
    </Page>
  );
}

export default AboutPage;
