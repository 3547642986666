import React from "react";
import Page from "../components/Page";
import RichText from "../components/RichText";

// Import all the functions we need
import { useSelector } from "react-redux";
import { usePullData } from "../hooks/usePullData";
import { renderCollection } from "../util/renderCollection";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import LoadingPage from "./LoadingPage";
import Classes from "../components/Classes";

function ClassesPage() {
  // access our data so that we can display it
  const singleTypeSlug = "classes-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // Collection data
  const collectionSlug = "classes";
  const collectionData = data[collectionSlug];

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;
  if (!collectionData) return <LoadingPage />;
  return (
    <Page data-wf-page="6356da9ec7d9f6c9d939a861" data-wf-site="6352a347a0546222959cdf3a">
      <div className="herosection classes wf-section">
        <div className="container w-container">
          <div className="text">
            <h1 data-w-id="8663679f-ae4b-510b-7154-f15d08db752d" style={{ opacity: 0 }} className="heading">
              {cmsData.mainHeading}
            </h1>
            <p data-w-id="8663679f-ae4b-510b-7154-f15d08db752f" style={{ opacity: 0 }} className="paragraph">
              <RichText text={cmsData.mainDesc}></RichText>
            </p>
          </div>
          <div className="image">
            <h1
              data-w-id="8663679f-ae4b-510b-7154-f15d08db7539"
              // style={{ opacity: 0 }}
              style={{
                opacity: 0,
                backgroundImage: `url(${cmsData.mainImage.data && cmsData.mainImage.data.attributes.url})`,
              }}
              className="heroimagebackground classes"
            >
              0
            </h1>
            <h1 data-w-id="8663679f-ae4b-510b-7154-f15d08db753b" style={{ opacity: 0 }} className="heroimagebackground _2 hero">
              0
            </h1>
          </div>
        </div>
      </div>
      <Classes data={collectionData} cmsData={cmsData} />

      <div style={{ display: "flex" }} className="preloader">
        <div
          className="lottie-animation"
          data-w-id="78bf4a73-5074-045c-052c-0d0c99d20f42"
          data-animation-type="lottie"
          data-src="documents/65014-dog-walking.json"
          data-loop={0}
          data-direction={1}
          data-autoplay={1}
          data-is-ix2-target={0}
          data-renderer="svg"
          data-default-duration="0.8333333333333334"
          data-duration={0}
        />
      </div>
    </Page>
  );
}

export default ClassesPage;
