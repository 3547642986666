import axios from "axios";
import { useNavigate } from "react-router-dom";
import { saveAuthData } from "../../util/saveAuthData";

export const AUTHENTICATE = "AUTHENTICATE";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_SIGNUP_SUCCESS = "AUTH_SIGNUP_SUCCESS";

export const register = (data) => {
  console.log("Registering: " + data.email);
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/auth/local/register`,
        data: {
          ...data,
          username: data.email,
        },
      };
      const response = await axios(config);

      dispatch(authenticate(response.data.jwt, response.data.user));
      // set our flag for the banner
      dispatch({
        type: AUTH_SIGNUP_SUCCESS,
        authSignupSuccess: true,
      });
    } catch (error) {
      // set our flag for the banner
      dispatch({
        type: AUTH_ERROR,
        authError: true,
      });
      throw error;
    }
  };
};

export const login = (data) => {
  console.log("Logging In");
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/auth/local`,
        data: data,
      };
      const response = await axios(config);
      dispatch(authenticate(response.data.jwt, response.data.user));
      // set our flag for the banner
      dispatch({
        type: AUTH_SUCCESS,
        authSuccess: true,
      });
    } catch (error) {
      // set our flag for the banner
      dispatch({
        type: AUTH_ERROR,
        authError: true,
      });
      throw error;
    }
  };
};

export const authenticate = (jwt, user) => {
  console.log("Authenticating");
  return async (dispatch) => {
    try {
      saveAuthData(user, jwt);
      dispatch({
        type: AUTHENTICATE,
        user: user,
        jwt: jwt,
      });
    } catch (error) {
      throw error;
    }
  };
};

export const logout = () => {
  console.log("Logging Out");
  return async (dispatch) => {
    try {
      saveAuthData(null, null);
      dispatch({
        type: AUTHENTICATE,
        user: null,
        jwt: null,
      });
    } catch (error) {
      throw error;
    }
  };
};

// clear our flags for the banner
export const clearAuthStatus = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: AUTH_ERROR,
        authError: false,
      });
      dispatch({
        type: AUTH_SUCCESS,
        authSuccess: false,
      });
      dispatch({
        type: AUTH_SIGNUP_SUCCESS,
        authSuccess: false,
      });
    } catch (error) {
      throw error;
    }
  };
};
