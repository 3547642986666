// Our Footer component
// Thsi will need to be modified to match the JSX coming from webflow

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Footer() {
  // access our data so that we can use it
  const { data } = useSelector((state) => state.cms);
  const singleTypeSlug = "footer";
  const footerData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!footerData) return null;

  return (
    <div className="footer wf-section">
      <div className="container class w-container">
        <Link to="/" className="link-block-3 w-inline-block">
          <img src={footerData.logo?.data?.attributes?.url || "images/FooterLogo.svg"} loading="lazy" alt="" className="image-6" />
        </Link>

        <h2 className="heading-10">{footerData.newsletterHeading}</h2>
        <div className="div-block-12">
          <div className="form-block w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              action={footerData.newsletterLink || "https://jbdogschool.us2.list-manage.com/subscribe/post?u=9e6971fa7add79747b015b61d&amp;id=47656db476&amp;f_id=00b949e0f0"}
              method="post"
              className="form"
            >
              <div className="div-block-10">
                <input type="text" className="text-field w-input" maxLength={256} name="FNAME" data-name="FNAME" placeholder="Enter your name" id="FNAME-2" />
                <input type="email" className="text-field w-input" maxLength={256} name="EMAIL" data-name="EMAIL" placeholder="Enter your email address" id="EMAIL-4" required="" />
              </div>
              <input type="submit" data-wait="Please wait..." defaultValue="Subscribe Now" className="button w-button" />
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
        <div className="div-block-13">
          <div className="social-media _2">
            <a href={footerData.facebookLink} target="_blank" rel="noreferrer" className="link-block w-inline-block">
              <img src="images/FacebookIcon.svg" loading="lazy" alt="" className="image-10" />
            </a>
            <a href={footerData.instagramLink} target="_blank" rel="noreferrer" className="w-inline-block">
              <img src="images/InstagramIcon.svg" loading="lazy" alt="" className="image-11" />
            </a>
          </div>
          <a href="https://www.civiconnect.ca" rel="noreferrer" target="_blank" className="link-block-4 w-inline-block">
            <img src="images/CivicConnect.svg" loading="lazy" alt="" className="image-7" />
          </a>
        </div>
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class _2 _3" />
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class _2" />
      </div>
    </div>
  );
}

export default Footer;
