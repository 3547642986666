import React, { useEffect, useState } from "react";
import { usePullData } from "../hooks/usePullData";
import { renderCollection } from "../util/renderCollection";
import RichText from "./RichText";
import { v4 as uuid } from "uuid";

const collectionSlug = "classes";

const Classes = ({ data, cmsData }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (data) {
      // build an array of categories
      let nonUniqueCategories = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].attributes.category.length; j++) {
          nonUniqueCategories.push(data[i].attributes.category[j].value);
        }
      }
      //   remove the duplicates
      let uniqueCategories = [...new Set(nonUniqueCategories)];
      setCategories(uniqueCategories);

      // filter based on selected filter
      let tmp = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].attributes.category.length; j++) {
          if (data[i].attributes.category[j].value === selectedFilter || selectedFilter === "All") {
            tmp.push(data[i]);
            break;
          }
        }
      }

      // set data to be displayed
      setFilteredData(tmp);
    }
  }, [data, selectedFilter]);

  return (
    <div className="classeslistsection wf-section">
      <div className="listcontainer w-container">
        <div className="classeslistfilters">
          <div className={`classesfilter ${selectedFilter === "All" && "filterActive"}`} onClick={() => setSelectedFilter("All")}>
            All
          </div>

          {categories.map((el) => {
            return (
              <div key={uuid()} className={`classesfilter ${selectedFilter === el && "filterActive"}`} onClick={() => setSelectedFilter(el)}>
                {el}
              </div>
            );
          })}

          <a href="https://www.thedogscents.com/" target="_blank" rel="noreferrer" className="classesfilter dogscentslink">
            TheDogScents
          </a>
        </div>
        <div className="classeslist">{renderCollection(CollectionEntry, filteredData, collectionSlug)}</div>
      </div>
    </div>
  );
};

export default Classes;

const CollectionEntry = (props) => {
  // we can now access any of our strapi fields using data.fieldName
  let data = usePullData(props);
  // This code gets repeated once per database entry in strapi.
  return (
    <div className="classinfo">
      <div className="classinfoheader">
        <h2 className="classheading">{data.title}</h2>
        <a href={data.registrationLink} className="button w-button">
          Register Now
        </a>
      </div>
      <div className="classinfotext">
        <p className="classdesc">
          <RichText text={data.description}></RichText>
        </p>
        <p className="classdesc activities">
          <RichText text={data.activitiesDescription}></RichText>
        </p>
      </div>

      <div className="classTagRow">
        {data.category &&
          data.category.map((el) => {
            return (
              <div key={uuid()} className="classtagdiv">
                <div className="classtag">{el.value}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
