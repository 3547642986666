import React from "react";
import Page from "../components/Page";
import { useSubmitForm } from "../hooks/useSubmitForm";

// Import all the functions we need
import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";

function ContactPage() {
  const { handleSubmit } = useSubmitForm();
  // access our data so that we can display it
  const singleTypeSlug = "contact-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;
  return (
    <Page data-wf-page="6356da85d7a8f80aac621a79" data-wf-site="6352a347a0546222959cdf3a">
      <iframe
        id="JotFormIFrame-213054605058046"
        title="Contact Us"
        onload="window.parent.scrollTo(0,0)"
        allowtransparency="true"
        allowfullscreen="true"
        allow="geolocation; microphone; camera"
        src="https://form.jotform.com/213054605058046"
        frameborder="0"
        style={{
          minWidth: "100%",
          maxWidth: "100%",
          height: "100vh",
          border: "none",
          marginBottom: 100,
        }}
      ></iframe>
    </Page>  );
}

export default ContactPage;
