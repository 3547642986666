import React from "react";
import Page from "../components/Page";
import RichText from "../components/RichText";

// Import all the functions we need
import { useSelector } from "react-redux";
import { usePullData } from "../hooks/usePullData";
import { renderCollection } from "../util/renderCollection";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import LoadingPage from "./LoadingPage";
import FAQs from "../components/FAQs";

function FAQPage() {
  // access our data so that we can display it
  const singleTypeSlug = "faq-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;
  //Collections Data
  const collectionSlug = "faqs";
  const collectionData = data[collectionSlug];

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;
  return (
    <Page data-wf-page="6356da4f90197b71b76cc1c7" data-wf-site="6352a347a0546222959cdf3a">
      <div className="updatessection faq wf-section">
        <div className="container updates w-container">
          <p data-w-id="d73b8855-1f6f-00b8-f52d-8cf1ea1ab6b7" style={{ opacity: 0 }} className="paragraph-12">
            {cmsData.smallHeading}
          </p>
          <h1 data-w-id="d73b8855-1f6f-00b8-f52d-8cf1ea1ab6b9" style={{ opacity: 0 }} className="heading-19">
            {cmsData.mainHeading}
          </h1>
          <p data-w-id="d73b8855-1f6f-00b8-f52d-8cf1ea1ab6bb" style={{ opacity: 0 }} className="paragraph-13">
            {cmsData.mainDesc}
          </p>
        </div>
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class _2 _3" />
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class _2" />
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class updates" />
      </div>
      <div className="faqmainsection wf-section">
        <FAQs data={collectionData} />
      </div>
      <div style={{ display: "flex" }} className="preloader">
        <div
          className="lottie-animation"
          data-w-id="107d7edd-203f-fd88-f04a-65354e7d4368"
          data-animation-type="lottie"
          data-src="documents/65014-dog-walking.json"
          data-loop={0}
          data-direction={1}
          data-autoplay={1}
          data-is-ix2-target={0}
          data-renderer="svg"
          data-default-duration="0.8333333333333334"
          data-duration={0}
        />
      </div>
    </Page>
  );
}

export default FAQPage;

const CollectionEntry = (props) => {
  // we can now access any of our strapi fields using data.fieldName
  let data = usePullData(props);

  // This code gets repeated once per database entry in strapi.
  return (
    <div data-hover="false" data-delay={0} data-w-id="410c6ef2-99a5-84e3-21b3-68cad6921e8f" className="faqdropdown w-dropdown">
      <div className="faqdropdowntoggle w-dropdown-toggle">
        <div className="arrow w-icon-dropdown-toggle" />
        <h2 className="heading-21">
          {data.question}
          <br />
        </h2>
      </div>
      <nav style={{ display: "none", opacity: 0, height: 0 }} className="faqanswer w-dropdown-list">
        <p className="faqanswertext">
          <RichText text={data.answer}></RichText>
        </p>
      </nav>
    </div>
  );
};
