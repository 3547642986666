// Our Navbar component
// This will need to be modified to match the JSX coming from webflow

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useAuthFunctions } from "../hooks/useAuthFunctions";

function Navbar() {
  // access our data so that we can use it
  const { data } = useSelector((state) => state.cms);
  const { user } = useSelector((state) => state.auth);

  const { handleLogout } = useAuthFunctions();

  const singleTypeSlug = "navbar";
  const navData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!navData) return null;

  return (
    <div data-animation="default" data-collapse="medium" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="navbar-2 w-nav">
      <div className="container nav w-container">
        <Link to="/" aria-current="page" className="brandlogo w-nav-brand w--current">
          <img style={{ height: navData.logoHeight || 70, objectFit: "contain" }} src={navData?.logo?.data?.attributes?.url || "images/Logo.png"} loading="lazy" alt="" className="image-16" />
        </Link>
        <nav role="navigation" className="nav-menu-2 w-nav-menu">
          {navData.link &&
            navData.link.map((item) => {
              return (
                <Link key={uuid()} to={item.slug} className="navlink w-nav-link">
                  {item.text}
                </Link>
              );
            })}
        </nav>
        <div className="menu-button w-nav-button">
          <div className="icon w-icon-nav-menu" />
        </div>
        <div className="navbuttons">
          {!user && (
            <Link to="/LogIn" className="button w-button">
              Log In
            </Link>
          )}

          <div data-hover="false" data-delay={0} className="dropdown w-dropdown" style={user ? { display: "inline-block" } : {}}>
            <div className="dropdown-toggle w-dropdown-toggle">
              <span className="button w-button">Account</span>
            </div>
            <nav className="dropdown-list w-dropdown-list">
              <Link to="/Updates" className="dropdown-link w-dropdown-link">
                Updates
              </Link>
              <span className="dropdown-link w-dropdown-link" onClick={handleLogout}>
                Logout
              </span>
            </nav>
          </div>
        </div>
      </div>
    </div>

    // <div
    //   style={{
    //     display: "flex",
    //     flexDirection: "row",
    //     justifyContent: "space-around",
    //     backgroundColor: "grey",
    //   }}
    // >
    //   {/* Display our navbar logo */}
    //   <img
    //     src={navData.logo.data && navData.logo.data.attributes.url}
    //     style={{ width: 200 }}
    //   />

    //   {/* loop through our nav links and display them one by one */}
    //   {navData.links &&
    //     navData.links.map((item) => {
    //       return (
    //         <Link key={uuid()} to={item.slug}>
    //           {item.text}
    //         </Link>
    //       );
    //     })}
    // </div>
  );
}

export default Navbar;
