import React from "react";
import Page from "../components/Page";

// Import all the functions we need
import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";

function UpdatesPage() {
  // access our data so that we can display it
  const singleTypeSlug = "news-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;
  return (
    <Page data-wf-page="6356db4516f82142cd8728db" data-wf-site="6352a347a0546222959cdf3a">
      <div className="updatessection wf-section">
        <div className="container updates w-container">
          <p data-w-id="1a5ef097-b3f1-3002-65a7-4b3ce08ff75f" style={{ opacity: 0 }} className="paragraph-12">
            Welcome
          </p>
          <h1 data-w-id="fc421c79-7b18-65dd-10a5-7bc73dc0a5cb" style={{ opacity: 0 }} className="heading-19">
            Recent Updates
          </h1>
          <p data-w-id="2ce9d9ed-7528-2fbe-3490-2193ee32c147" style={{ opacity: 0 }} className="paragraph-13">
            Catch up on all your updates.
          </p>
        </div>
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class _2 _3" />
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class _2" />
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class updates" />
      </div>
      <div className="updatessection wf-section">
        <div className="container updates w-container">
          {cmsData.News &&
            cmsData.News.map((item) => {
              return (
                <div className="div-block-18">
                  <h2 data-w-id="d2d11d69-ff5b-516a-21b2-0f7247e505ae" style={{ opacity: 0 }} className="updatesheading">
                    {item.newsHeading}
                  </h2>
                  <p data-w-id="4f9a692d-24fd-01e9-7a08-840a7fc25e37" style={{ opacity: 0 }} className="paragraph-14">
                    {item.newsDate}
                  </p>
                  <p data-w-id="91d66fa2-d29f-ae7b-914e-aca8199a6332" style={{ opacity: 0 }} className="paragraph-15">
                    {item.newsAuthor}
                  </p>
                  <p data-w-id="764c43f4-d280-d2d0-d4b6-f826b02bbb5f" style={{ opacity: 0 }} className="paragraph-16">
                    {item.newsText}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
      <div className="classschedulesection wf-section">
        <div className="container class w-container">
          <h1 data-w-id="70e3b145-0bb0-10e6-0d49-91b73a97eb4c" style={{ opacity: 0 }} className="heading-5">
            Class Schedules
          </h1>
          <p data-w-id="70e3b145-0bb0-10e6-0d49-91b73a97eb4e" style={{ opacity: 0 }} className="paragraph-4">
            Check out our upcoming classes and register now!
          </p>
          <div className="calendar w-embed w-iframe">
            <iframe src={cmsData.calendarUrl} style={{ border: "solid 1px #777" }} width={1030} height={600} frameBorder={0} scrolling="no" />
          </div>
          <div className="calendar phone w-embed w-iframe">
            <iframe src={cmsData.calendarUrl} style={{ border: "solid 1px #777" }} width={300} height={300} frameBorder={0} scrolling="no" />
          </div>
          <div className="calendar tablet w-embed w-iframe">
            <iframe src={cmsData.calendarUrl} style={{ border: "solid 1px #777" }} width={500} height={300} frameBorder={0} scrolling="no" />
          </div>
        </div>
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class _2 _3" />
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class _2" />
        <img src="images/Paw.svg" loading="lazy" alt="" className="image-2 class" />
      </div>
      <div style={{ display: "flex" }} className="preloader">
        <div
          className="lottie-animation"
          data-w-id="397b0c73-fdb4-fcfb-ea26-f9545e9a4a32"
          data-animation-type="lottie"
          data-src="documents/65014-dog-walking.json"
          data-loop={0}
          data-direction={1}
          data-autoplay={1}
          data-is-ix2-target={0}
          data-renderer="svg"
          data-default-duration="0.8333333333333334"
          data-duration={0}
        />
      </div>
    </Page>
  );
}

export default UpdatesPage;
